import CacheBuster from 'react-cache-buster';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import APP_DATA from '../../package.json';
import "./App.scss";
import SladeRoutes from "./Routes";
import Layout from "components/layout/Layout";
import LoadingSpinner from "components/loading_spinner/LoadingSpinner";


function App() {
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LfC_dAlAAAAAHHHt7zIHkyDeS-j3Ri1rXUiWsZ_">
      <CacheBuster
        currentVersion={APP_DATA.version}
        isEnabled={isProduction} //If false, the library is disabled.
        loadingComponent={<LoadingSpinner />}
      >
        <Layout>
            <SladeRoutes />
        </Layout> 
      </CacheBuster>  
    </GoogleReCaptchaProvider>
  )
}

export default App;
