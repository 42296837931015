import React, { Suspense } from 'react';
import { Routes, Route } from "react-router-dom";
import PrivateOutlet from "components/hoc/PrivateOutlet";
import PublicOutlet from "components/hoc/PublicOutlet";
import CapabilityOutlet from "components/hoc/CapabilityOutlet";
import LoadingSpinner from "components/loading_spinner/LoadingSpinner";


const Home = React.lazy(() => import("pages/home/Home"));
const About = React.lazy(() => import("pages/About"));
const StabilityIndex = React.lazy(() => import("pages/stability/Index"));
const StabilityList = React.lazy(() => import("pages/stability/List"));
const StabilitySingle = React.lazy(() => import("pages/stability/Single"));
const ClinicalRequestsIndex = React.lazy(() => import("pages/clinicalRequests/Index"));
const ClinicalRequests = React.lazy(() => import("pages/clinicalRequests/List"));
const ClinicalRequestForm = React.lazy(() => import("pages/clinicalRequests/Form"));
const Pricing = React.lazy(() => import("pages/Pricing"));
const TeamIndex = React.lazy(() => import("pages/team/Index"));
const Teams = React.lazy(() => import("pages/team/List"));
const Team = React.lazy(() => import("pages/team/Single"));
const EducationIndex = React.lazy(() => import("pages/education/Index"));
const EducationList = React.lazy(() => import("pages/education/List"));
const EducationSingle = React.lazy(() => import("pages/education/Single"));
const Catalogue = React.lazy(() => import("pages/Catalogue"));
const Contact = React.lazy(() => import("pages/Contact"));
const Links = React.lazy(() => import("pages/Links"));
const Components = React.lazy(() => import("pages/Components"));
const AuthForm = React.lazy(() => import("pages/authForms/Index"));
const Privacy = React.lazy(() => import("pages/Privacy"));
const Disclaimer = React.lazy(() => import("pages/Disclaimer"));
const Error404 = React.lazy(() => import("pages/Error404"));
const Account = React.lazy(() => import("pages/Account"));
const Report = React.lazy(() => import("pages/Report"));
const BlogList = React.lazy(() => import("pages/blog/List"));
const BlogIndex = React.lazy(() => import("pages/blog/Index"));
const BlogSingle = React.lazy(() => import("pages/blog/Single"));
const Search = React.lazy(() => import("pages/Search"));
const RequestCallback = React.lazy(() => import("pages/RequestCallback"));
const FormConfirmation = React.lazy(() => import("pages/FormConfirmation"));
const ClinicalTrialsIndex = React.lazy(() => import("pages/clinicalTrials/Index"));
const ClinicalTrialsList = React.lazy(() => import("pages/clinicalTrials/List"));
const ClinicalTrialsSingle = React.lazy(() => import("pages/clinicalTrials/Single"));
const ClinicalTrialsFeasibilityFormHandler = React.lazy(() => import("pages/clinicalTrials/FeasibilityFormResponseHandler"));
const ClinicalTrialsFeasibilityFormConfirmation = React.lazy(() => import("pages/clinicalTrials/ClinicalTrialsFeasibilityFormConfirmation"));
const LearningModuleSingle = React.lazy(() => import("pages/clinicalResources/learning-modules/Single"));
const LearningModuleResourceSingle = React.lazy(() => import("pages/clinicalResources/learning-modules/SingleResource"));
const CpdLog = React.lazy(() => import("pages/CPD"));
const ClinicalResourcesIndex = React.lazy(() => import("pages/clinicalResources/Index"));
const ClinicalResourcesList = React.lazy(() => import("pages/clinicalResources/List"));
const ClinicalResourcesSingle = React.lazy(() => import("pages/clinicalResources/Single"));



function SladeRoutes() {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Routes>
                {/* Routes which are not accessible when user is logged in */}
                <Route element={<PublicOutlet />}>
                    <Route exact path="/login" element={<AuthForm type="login" />}></Route>
                    <Route exact path="/register" element={<AuthForm type="register" />}></Route>
                    <Route exact path="/forgot-password" element={<AuthForm type="forgot" />}></Route>
                </Route>

                {/* Routes accessible to everyone */}
                <Route exact path="/" element={<Home />}></Route>
                <Route exact path="/about" element={<About />}></Route>
                <Route exact path="/privacy-policy" element={<Privacy />}></Route>
                <Route exact path="/disclaimer" element={<Disclaimer />}></Route>
                <Route exact path="/contact" element={<Contact />}></Route>
                <Route exact path="/components" element={<Components />}></Route>
                <Route exact path="/reset-password" element={<AuthForm type="reset" />}></Route>
                <Route exact path="/confirmation" element={<FormConfirmation />}></Route>
                
                {/* Routes accessible to logged in users/capabilities */}
                <Route element={<PrivateOutlet />}>
                    <Route element={<CapabilityOutlet capability="wb_edit_current_user" />}>
                        <Route exact path="/account" element={<Account />}></Route>
                        <Route exact path="/search" element={<Search />}></Route>
                        <Route exact path="/search/:keyword" element={<Search />}></Route>
                    </Route>
                    <Route element={<CapabilityOutlet capability="wb_read_product_catalogue" />}>
                        <Route exact path="/product-catalogue" element={<Catalogue />}></Route>
                    </Route>
                    <Route element={<CapabilityOutlet capability="wb_read_pricing_requests" />}>
                        <Route exact path="/pricing-requests" element={<Pricing />}></Route>
                    </Route>
                    <Route element={<CapabilityOutlet capability="wb_read_stability_matrix" />}>
                        <Route path="stability" element={<StabilityIndex />}>
                            <Route index element={<StabilityList />} />
                            <Route path=":slug" element={<StabilitySingle />} />
                        </Route>
                    </Route>
                    <Route element={<CapabilityOutlet capability="wb_read_quick_links" />}>
                        <Route exact path="/quick-links" element={<Links />}></Route>
                    </Route>
                    <Route element={<CapabilityOutlet capability="wb_read_key_contacts" />}>
                        <Route path="meet-the-team" element={<TeamIndex />}>
                            <Route index element={<Teams />} />
                            <Route path=":teamSlug" element={<Team />} />
                        </Route>
                    </Route>
                    <Route element={<CapabilityOutlet capability="wb_read_report_issue" />}>
                        <Route exact path="/report-an-issue" element={<Report />}></Route>
                        <Route exact path="/request-callback" element={<RequestCallback />}></Route>
                    </Route>
                    <Route element={<CapabilityOutlet capability="wb_read_education" />}>
                        <Route path="education" element={<EducationIndex />}>
                            <Route index element={<EducationList />} />
                            <Route path=":parent" element={<EducationSingle />} />
                            <Route path=":parent/:single" element={<EducationSingle />} />
                        </Route>
                    </Route>
                    <Route element={<CapabilityOutlet capability="wb_read_education" />}>
                        <Route exact path="/cpd-log" element={<CpdLog />}></Route>
                    </Route>
                    <Route element={<CapabilityOutlet capability="wb_read_blog" />}>
                        <Route exact path="/important-updates" element={<BlogIndex />}>
                            <Route index element={<BlogList />} />
                            <Route path=":slug" element={<BlogSingle />} />
                        </Route>
                    </Route>
                    <Route element={<CapabilityOutlet capability="wb_read_clinical_requests" />}>
                        <Route path="clinical-requests" element={<ClinicalRequestsIndex />}>
                            <Route index path="" element={<ClinicalRequests />} />
                            <Route path=":category" element={<ClinicalRequests />} />
                            <Route path="form/:slug" element={<ClinicalRequestForm />} />
                        </Route>
                    </Route>
                    <Route element={<CapabilityOutlet capability="wb_read_clinical_trials" />}>
                        <Route path="clinical-trials" element={<ClinicalTrialsIndex />}>
                            <Route index element={<ClinicalTrialsList />} />
                            <Route path="meet-the-team/:teamSlug" element={<Team />} />
                            <Route path="feasibility-form/handler" element={<ClinicalTrialsFeasibilityFormHandler />} />
                            <Route path="feasibility-form/confirmation" element={<ClinicalTrialsFeasibilityFormConfirmation />} />
                            <Route path=":slug" element={<ClinicalTrialsSingle />} />
                        </Route>
                    </Route>
                    <Route element={<CapabilityOutlet capability="wb_read_clinical_resources" />}>
                        <Route path="clinical-resources" element={<ClinicalResourcesIndex />}>
                            <Route index element={<ClinicalResourcesList />} />
                            <Route path="learning-modules/resource/:single" element={<LearningModuleResourceSingle />} />
                            <Route path="learning-modules/:parent" element={<LearningModuleSingle />} />
                            <Route path="learning-modules/:parent/:single" element={<LearningModuleSingle />} />
                            <Route path=":parent" element={<ClinicalResourcesSingle />} />
                            <Route path=":parent/:single" element={<ClinicalResourcesSingle />} />
                        </Route>
                    </Route>
                </Route>
                <Route path="*" element={<Error404 />} />
            </Routes>
        </Suspense>
    )
}

export default SladeRoutes;